<template>
  <div class="section-box not-border" :class="{'disable': showOnly}">
    <div
       ref="dropArea"
       col="fs fs"
       wrap="">
      <h3 class="section-box-title" v-if="!showOnly"> Вложения </h3>
      <div class="attributes-label" v-if="!showOnly"> В случае необходимости в подтверждении своих доводов вы вправе приложить к обращению необходимые документы и материалы в электронной форме. </div>
      <div class="attachment-buttons">
        <button type="button" class="simple" @click="imgSrc && showOnly ? false : $refs.file.click()" v-if="!showOnly">Прикрепить</button>
        <tippy toSelector=".cssSelector">
          Объем загружаемых файлов не более 50 Мб.
          Допустимые форматы:
          Видео: mp4, avi, mov. 
          Архивы: zip, rar, tar, 7z. 
          Изображения: tiff, jpg, jpeg, png. 
          Документы: doc, docx, pdf, txt, xlsx, xls, ods.
          Иные форматы файлов не обрабатываются приложением.
        </tippy>
        <i class="mfcicon-question cssSelector"></i>
      </div>
      <div class="documents">
        <div class="document-wrapper" v-for="doc in documents" :key="doc.id">
          <div class="document" row="fc cn">
            <i class="mfcicon-text-snippet document-text-snippet-icon position-center"></i>    
            <a class="link" :href="doc.url" target="_blank" v-if="doc.url">{{doc.description | fileName}}</a>
            <p class="fake-link" v-else>{{doc.description}}</p>
            <i class="mfcicon-delete document-delete-icon position-center" @click="deleteDocument(doc)"></i>
            <pulse-loader v-if="doc.loaded" color="#d4391d" size="7px"></pulse-loader>
          </div>
          <span class="error" v-if="doc.fail">{{doc.fail}}</span>
        </div>
      </div>
    </div>

    <input type="file"
           ref="file"
           accept=".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xlsx, .xls, .ods"
           v-show="false"
           @change="handleFile($event.target.files)"
           :disabled="uploading || $attrs.disabled"
           v-bind="fileInputAttrs">
    <input 
      type="hidden" 
      v-for="doc in documents" 
      :value="doc.id" 
      :name="'claim[document_ids][]'"
      :key="doc.id"
    >
    <input
      v-if="documents.length === 0"
      type="hidden"
      :name="'claim[document_ids][]'"
    >
  </div>

</template>

<script>
  import first from 'lodash/first'
  import findIndex from 'lodash/findIndex'
  import isEqual from 'lodash/isEqual'
  import uploaderHelperMixin from '../../mixins/uploaderHelperMixin'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import toastr from 'toastr'

  export default {
    name: 'ClaimDocuments',
    components: {PulseLoader},
    mixins: [uploaderHelperMixin],
    props: {
      value: {
        type: [Array],
      },
      showOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uploadedSrc: null,
        highlight: false,
        fileId: null,
        documents: [],
        cancelRequest: null,
        validFileTypes: ".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xlsx, .xls, .ods"
      }
    },
    mounted () {
      this.documents = this.value;
      this.$store.commit('SET_STATE', {path: ['claim', 'attachments'], value: this.documents})
      this.$store.commit('SET_STATE', {path: ['initialAttachments'], value: this.value})
    },
    computed: {
      imgSrc() {
        return this.uploadedSrc || this.src
      },
      fileInputAttrs() {
        const {...attrs} = this.attrs
        return attrs
      },
      uploading: {
        get() {
          return this.$store.state.attachmentUploading
        },
        set(v) {
          this.$store.commit('SET_STATE', {path: ['attachmentUploading'], value: v})
        }
      }
    },
    methods: {
      async handleFile(files) { 
        const file = first(files)
        this.$refs['file'].value = null
        if (!file || this.uploading || !this.validFile(file)) return

        const fakeFile = this.fakeFileConfiguration(file)
        this.documents.push(fakeFile)

        this.$store.commit('SET_STATE', {path: ['claim', 'attachment_loaded'], value: true})

        try {
          this.uploading = true
          const formData = new FormData()

          formData.append('attachment[file]', file)
          formData.append('attachment[kind]', "claim_document")
          formData.append('attachment[type]', "Attachment::Document")
          
          const CancelToken = this.$axios.CancelToken
          const vm = this
          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData, {
            cancelToken: new CancelToken(function executor(c) {
              vm.cancelRequest = c;
            })
          })
          data.resource.loaded = false
          this.documents = this.replaceObjectInArray(this.documents, data.resource, fakeFile)
          this.$store.commit('SET_STATE', {path: ['claim', 'attachments'], value: this.documents})
          this.$store.commit('SET_STATE', {path: ['attachmentChanged'], value: !isEqual(this.documents, this.$store.state.initialAttachments)})
        } catch (e) {
          this.documents.forEach(item => {
            if (item.id === fakeFile.id) {
              item.loaded = false
              item.for_delete = true
              item.fail = 'Произошла ошибка, попробуйте снова.'
            }
          });
          console.error("ERROR", e)
        } finally {
          this.cancelRequest = null
          this.uploading = false
          this.$store.commit('SET_STATE', {path: ['claim', 'attachment_loaded'], value: false})
        }
      },
      async deleteDocument (doc) {
        if (!doc.for_delete) {
          if (this.cancelRequest) {
            this.cancelRequest()
          } else {
            return
          }
        }
        let idx = findIndex(this.documents, function(d) { return d.id == doc.id; });
        if (idx != -1) {
          this.documents.splice(idx, 1)
          this.$store.commit('SET_STATE', {path: ['claim', 'attachments'], value: this.documents})
          this.$store.commit('SET_STATE', {path: ['attachmentChanged'], value: !isEqual(this.documents, this.$store.state.initialAttachments)})
        }
      },
      validFile(file) {
        if (this.converter(file.size) > 50) {
          toastr.error('Файл большого размера (максимальный размер - 50 MB)')
          return false
        }
        if (!this.validFileTypes.includes(this.fileType(file.name.toLowerCase()))) {
          toastr.error('Файл имеет неверный формат. Разрешено загружать - jpg, jpeg, png, mp4, avi, mov, mkv, zip, rar, tar, gz, 7z, jpeg, png, tiff, doc, docx, pdf')
          return false
        }
        return true
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';

  button.simple {
    margin-top: 19px;
  }

  .disable {
    margin: 0!important;
    padding: 0!important;
  }

  .documents {
    .document-wrapper {
      .document {
        display: flex;
        align-items: center;
        &-text-snippet-icon {
          font-size: 14px;
          margin-right: 9px;
        }
        .link {
          color: rgba(21, 43, 78, 1);
          font-size: 18px;
          cursor: pointer;

          &:hover {
            text-decoration: underline !important;
          }
        }
        .fake-link {
          margin: 0;
          color: rgba(21, 43, 78, 1);
          font-size: 18px;
        }
        &-delete-icon {
          cursor: pointer;
          margin-left: 7px;
          font-size: 14px;
          transition: .1s ease;

          &:hover {
            color: rgba(21, 43, 78, 1)
          }
        }
        .v-spinner {
          margin-left: 10px;
        }
      }

      .error {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
        color: rgb(255, 26, 26);
      }

      &:first-child {
        margin-top: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .position-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
