import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import {dynamicSetState} from "./utils"

Vue.use(Vuex)

const initStore = () => new Vuex.Store({
  state: {
    errors: {},
    questionChanged: false,
    oldQuestion: null,
    todayInNewSchedule: false,
    startAdditionalConsultation: false,
    citizenHasInvalidRegistration: false,
    citizenId: 0
  },
  mutations: {
    SET_STATE(state, payload) {
      dynamicSetState(state, payload)
    },
    SET_ERRORS(state, payload) {
      dynamicSetState(state.errors, payload, true)
    }
  }
})

export default initStore